import React, { useState } from 'react';
import crashcourseinromance from '../../kdramaimages/crashcourseinromance.jpg';
import kingtheland from '../../kdramaimages/king-the-land.jpg';
import seeyouinmy19thlife from '../../kdramaimages/see-you-in-my-19th-life.jpg';
import theuncannycounter from '../../kdramaimages/the-uncanny-counter.jpg';

import getVideoId from 'get-video-id'; // Import the get-video-id library or use your own implementation

const KdramaHero = () => {
  const slides = [
    {
      title: 'Crash Course in Romance',
      description:
        "Retired ex national team female handball player has become a grocery store owner in order to look after her family while she grapples with her daughter's tutor's eating disorder.",
      image: crashcourseinromance,
      url: 'https://www.youtube.com/watch?v=M0roNIisQ5w',
    },
    {
      title: 'King the Land',
      description:
        "Amid a tense inheritance fight, a charming heir clashes with his hardworking employee who's known for her irresistible smile, which he cannot stand.",
      image: kingtheland,
      url: 'https://www.youtube.com/watch?v=AGF16szMOmo',
    },
    {
      title: 'See You in My 19th Life',
      description:
        "Ban Ji-eum, who can remember all her past lives, sets out to reconnect with a lost love from her 18th life, which was cut tragically short.",
      image: seeyouinmy19thlife,
      url: 'https://www.youtube.com/watch?v=orAIH67EU-I',
    },

    {
      title: 'The Uncanny Counter S2',
      description:
        "Noodle shop employees by day and demon hunters by night, the Counters use special abilities to chase down malevolent spirits that prey on humans",
      image: theuncannycounter,
      url: 'https://www.youtube.com/watch?v=LOXTIrTcwVk',
    },
  ];

  const [currentSlide] = useState(0);
  const [overlay, setOverlay] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');

  const handleOverlayClick = () => {
    setOverlay(false);
    setCurrentVideoUrl('');
  };

  const handleTrailerClick = (url) => {
    setCurrentVideoUrl(url);
    setOverlay(true);
  };

  const getYouTubeEmbedUrl = (url) => {
    const videoId = getVideoId(url);
    if (videoId) {
      return `https://www.youtube.com/embed/${videoId.id}?modestbranding=1&hd=1&autoplay=1&loop=1`;
    }
    return '';
  };

  return (
    <div className='flex justify-center mt-20'>
      <div className='w-full max-w-[1440px] mx-auto'>
        <div className='flex flex-row mx-5 overflow-x-scroll relative snap-x snap-mandatory scrollbar-hide'>
          {slides.map((slide, index) => (
            <div
              className={`snap-center ${index === currentSlide ? 'active' : ''}`}
              key={index}
              style={{ marginRight: '2rem', minWidth: '100%', flex: '0 0 100%' }}
            >
              <div className='relative flex flex-row md-min:bg-pink-500/80 rounded-lg'>
                <div className='md-max:absolute text-content m-10 font-bold w-full h-[300px] z-[7]'>
                  {/*Slide text*/}
                  <div className='md-max:absolute md-max:top-[8rem] md-max:flex md-max:flex-col'>
                    <h2 className='uppercase text-[40px] text-white md-range:w-[50%] sm-max:w-[80%] leading-tight overflow-hidden line-clamp-2'>
                      {slide.title}
                    </h2>
                    <p className='text-black md-max:text-white text-[20px] md-max:line-clamp-2 md-range:w-[50%] sm-max:w-[80%] leading-tight'>
                      {slide.description}
                    </p>
                    <button
                      onClick={() => handleTrailerClick(slide.url)}
                      className='md-min:absolute md-min:bottom-[5rem] w-[100px] outline md-max:outline-white md-max:text-white md-max:mt-3 rounded-lg px-3 py-1 uppercase text-bold'
                    >
                      Trailer
                    </button>
                  </div>
                </div>

                {/*Overlay for smaller screens*/}
                <div className='md-max:absolute md-max:inset-0 z-[6]'>
                  <div className='md-max:absolute md-max:inset-0 md-max:bg-gradient-to-r md-max:from-black md-max:to-transparent md-max:rounded-md'></div>
                </div>

                {/*Slide image*/}
                <img
                  src={slide.image}
                  alt={slide.title}
                  className='w-[800px] h-[400px] md-max:w-full md-max:rounded-lg md-max:object-cover rounded-r-lg'
                 
                />
                {/* Embed YouTube video */}
                {overlay && slide.url === currentVideoUrl && (
                  <div onClick={handleOverlayClick} className='bg-black/80 fixed w-full h-screen z-[20] top-0 left-0 flex justify-center items-center'> 
                    <iframe
                      className='w-[70%] h-[65%]'
                      src={getYouTubeEmbedUrl(slide.url)}
                      title='YouTube video player'
                      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                      allowFullScreen
                    ></iframe>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default KdramaHero;
