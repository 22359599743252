import React from 'react';
import kdrama_icon from '../../images/kdrama101.png';
import { useNavigate, useLocation  } from 'react-router-dom';

const Hero = ({ handleScroll }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const goToNewsPage = () => {
    if (location.pathname === "/news") {
      window.location.href = "/news";
    } else {
      navigate("/news");
    }
  };

  const goToDailyPage = () => {
    if (location.pathname === "/random") {
      window.location.href = "/random";
    } else {
      navigate("/random");
    }
  };

  const goToReleasesPage = () => {
    if (location.pathname === "/releases") {
      window.location.href = "/releases";
    } else {
      navigate("/releases");
    }
  };
  return (
    <div className="relative h-screen flex flex-col items-center justify-center">
    <div className="flex flex-col items-center">
      <img src={kdrama_icon} alt="" className="w-[340px] h-[144px] mb-4" />
      <div className="flex items-center flex-row rounded-lg bg-white divide-x-2">
        <div onClick={goToReleasesPage} className="text-black font-bold w-30 px-5 uppercase hover:bg-pink-400 rounded-l-lg cursor-pointer">
          Releases
        </div>
        <div onClick={goToNewsPage}  className="text-black font-bold w-30 px-5 uppercase hover:bg-pink-400 cursor-pointer">
          News
        </div>
        <div onClick={goToDailyPage} className="text-black font-bold w-30 px-5 uppercase hover:bg-pink-400 rounded-r-lg cursor-pointer">
          Random
        </div>
      </div>
    </div>
    <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 flex items-center">
      <div className="mouse-wheel " onClick={handleScroll}>
        <div className="mouse hover:bg-pink-400">
          <div className="scroll"></div>
        </div>
    </div>
  </div>
  </div>
  );
};

export default Hero;