import React, { useState } from "react";
import getVideoId from 'get-video-id';

const ReleasesCard = ({ image, info, title, genre, watch, date, url }) => {
  const [showVideoOverlay, setShowVideoOverlay] = useState(false);

  const handleCardClick = () => {
    setShowVideoOverlay(true);
  };

  const handleOverlayClick = () => {
    setShowVideoOverlay(false);
  };

  const getYouTubeEmbedUrl = (url) => {
    const videoId = getVideoId(url);
    if (videoId && videoId.id) {
      return `https://www.youtube.com/embed/${videoId.id}?modestbranding=1&hd=1&autoplay=1&loop=1`;
    }
    return '';
  };

  return (
    <div className="relative">
      <div className="hover:scale-105 duration-500 transition bg-white/60 h-[300px] w-full rounded-lg cursor-pointer" onClick={handleCardClick}>
        <div className="flex flex-col divide-y-2 divide-black">
          <h1 className="text-center p-2 text-xl font-bold line-clamp-1">
            {title}
          </h1>
          <div className="flex flex-row divide-x-2 divide-black">
            <div className="w-[50%] h-[254px] flex-shrink-0">
              <img
                src={image}
                alt=""
                className="rounded-bl-lg w-full h-full object-cover"
              />
            </div>
            <div className="flex flex-col">
              <p className="text-black text-m mb-2 ml-2 font-bold line-clamp-3">
                {info}
              </p>
              <p className="text-black text-m ml-4">
                <strong>GENRE:</strong> {genre}
              </p>
              <p className="text-black text-m ml-4">
                <strong>WATCH:</strong> {watch}
              </p>
              <p className="text-black text-m ml-4">
                <strong>DATE:</strong> {date}
              </p>
            </div>
          </div>
        </div>
      </div>
      {showVideoOverlay && (
        <div
          onClick={handleOverlayClick}
          className="bg-black/80 fixed w-full h-screen z-[20] top-0 left-0 flex justify-center items-center"
        >
          <iframe
            className="w-[70%] h-[65%]"
            src={getYouTubeEmbedUrl(url)}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      )}
    </div>
  );
};
export default ReleasesCard;
