import React, { useState, useEffect, useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import FormRegister from "./FormRegister";
import axios from "axios";
import { toast } from "react-hot-toast";

const FormLogin = ({ setForm, catUserRef }) => {
  const [registerUser, setRegisterUser] = useState(false);
  const [showWrapper, setShowWrapper] = useState(true);
  const handleCloseForm = () => {
    setForm(false);
  };
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission here, e.g., send data to the server
    const { username, password } = formData;
    try {
      const { data } = await axios.post("/login", {
        username,
        password,
      });
      if (data.error) {
        toast.error(data.error);
      } else {
        setFormData({});
        window.location.reload();
      }
    } catch (error) {}
  };

  const wrapperRef = useRef(null);

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    // Close the form if the click is outside the wrapper element
    if (
      showWrapper &&
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target) &&
      catUserRef.current &&
      !catUserRef.current.contains(event.target)
    ) {
      setForm(false);
    }
  };

  return (
    <div>
      {showWrapper && (
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[30]">
          <form
            ref={wrapperRef}
            className="bg-white h-[300px] w-[400px] rounded-lg p-5"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col space-y-4">
              <div className="flex justify-between items-center">
                <h1 className="font-bold text-2xl">Please Sign-in</h1>
                <AiOutlineClose
                  onClick={handleCloseForm}
                  className="sm:w-[15px] sm:h-[15px] lg:w-[20px] lg:h-[20px] cursor-pointer"
                />
              </div>

              <input
                placeholder="Username"
                type="text"
                id="username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                className="p-2 border border-gray-500 rounded-md focus:outline-black"
              />

              <input
                placeholder="Password"
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="p-2 border border-gray-500 rounded-md focus:outline-black"
              />

              <button
                type="submit"
                className="bg-pink-300 text-white py-2 px-4 rounded-md hover:bg-pink-500"
              >
                Submit
              </button>
              <h1
                onClick={() => {
                  setRegisterUser(!registerUser);
                  setShowWrapper(!showWrapper);
                }}
                className="hover:underline cursor-pointer font-bold text-sm"
              >
                Don't have an account. Click here to make an account.
              </h1>
            </div>
          </form>
        </div>
      )}
      {registerUser ? (
        <FormRegister setForm={setForm} setRegisterUser={setRegisterUser} />
      ) : null}
    </div>
  );
};

export default FormLogin;
