import React, { useState, useEffect, useRef } from "react";
import { AiOutlineClose, AiOutlineHome, AiOutlineMenu, AiOutlineCalendar } from "react-icons/ai";
import { BsNewspaper } from "react-icons/bs";
import {FaRandom}from "react-icons/fa";
import cat_user from "../../images/cat_user.png";
import { useNavigate, useLocation } from "react-router-dom";
import FormLogin from "../forms/FormLogin";
import { useContext } from "react";
import { userContext } from "../../context/UserContext";

const Navbar = () => {
  const {user } = useContext(userContext);
  const [nav, setNav] = useState(false);
  const [form, setForm] = useState(false);
  const navigate = useNavigate();
  const catUserRef = useRef(null);

  const location = useLocation();

  const goToNewsPage = () => {
    if (location.pathname === "/news") {
      window.location.href = "/news";
    } else {
      navigate("/news");
    }
  };

  const goToHomePage = () => {
    if (location.pathname === "/") {
      window.location.href = "/";
    } else {
      navigate("/");
    }
    
  };

  const goToDailyPage = () => {
    if (location.pathname === "/random") {
      window.location.href = "/random";
    } else {
      navigate("/random");
    }
  };

  const goToReleasesPage = () => {
    if (location.pathname === "/releases") {
      window.location.href = "/releases";
    } else {
      navigate("/releases");
    }
  };

  const handleCatUserClick = () => {
    setForm(!form);
  };

  return (
    <div>
      <div className="fixed top-0 left-0 w-full z-[10] bg-pink-300">
        <div className="max-w-[1440px] mx-auto px-4 py-1">
          {/*navibar button*/}
          <div className="flex justify-between items-center">
            <div className="flex flex-row items-center">
              <AiOutlineMenu
                className="h-[30px] w-[30px] cursor-pointer"
                onClick={() => setNav(!nav)}
              />
              <h1 className="uppercase font-bold text-white text-[30px]">
                kdrama 101
              </h1>
            </div>
            <div className="flex flex-row"> 
            {!!user && <h1 className="pr-3 text-white text-2xl">Hi, {user.username} </h1>}
            <div className="border-2 border-white">
              <img
                src={cat_user}
                alt=""
                ref={catUserRef}
                className="h-[30px] w-[30px] cursor-pointer"
                onClick={handleCatUserClick}
              />
            </div>
            </div>
          </div>
          {nav ? (
            <div
              onClick={() => setNav(!nav)}
              className="bg-black/70 fixed w-full h-screen z-10 top-0 left-0"
            >
              {" "}
            </div>
          ) : (
            ""
          )}
          {/*navibar title*/}
          <div
            className={
              nav
                ? "fixed top-0 left-0 w-[300px] h-screen bg-pink-300 z-10 duration-300 divide-y-2 divide-white/40"
                : "fixed top-0 left-[-100%] w-[300px] h-screen bg-white z-10 duration-300"
            }
          >
            <div className="flex justify-between items-center p-3">
              <h2 className="text-black font-bold sm:text-[15px] lg:text-[20px] uppercase">
                Kdrama 101
              </h2>
              <AiOutlineClose
                onClick={() => setNav(!nav)}
                className="sm:w-[15px] sm:h-[15px] lg:w-[20px] lg:h-[20px] cursor-pointer"
              />
            </div>

            {/*navbar list*/}
            <ul className="flex flex-col p-5">
              <h1
                onClick={goToHomePage}
                className="text-black font-bold sm:text-[10px] lg:text-[15px] py-3 uppercase hover:underline flex items-center"
              >
                <AiOutlineHome className="mr-5 sm:w-[10px] sm:h-[10px] lg:w-[15px] lg:h-[15px]" />
                Home
              </h1>
              <h2
                onClick={goToNewsPage}
                className="text-black font-bold sm:text-[10px] lg:text-[15px] py-3 uppercase hover:underline flex items-center"
              >
                <BsNewspaper className="mr-5 sm:w-[10px] sm:h-[10px] lg:w-[15px] lg:h-[15px]" />
                News
              </h2>
              <h3
                onClick={goToDailyPage}
                className="text-black font-bold sm:text-[10px] lg:text-[15px] py-3 uppercase hover:underline flex items-center"
              >
                <FaRandom className="mr-5 sm:w-[10px] sm:h-[10px] lg:w-[15px] lg:h-[15px]" />
                Random
              </h3>
              <h4
                onClick={goToReleasesPage}
                className="text-black font-bold sm:text-[10px] lg:text-[15px] py-3 uppercase hover:underline flex items-center"
              >
                <AiOutlineCalendar className="mr-5 sm:w-[10px] sm:h-[10px] lg:w-[15px] lg:h-[15px]" />
                Releases
              </h4>
            </ul>
          </div>
        </div>
      </div>
      {form ? <FormLogin catUserRef={catUserRef} setForm={setForm} /> : ""}
    </div>
  );
};
export default Navbar;
