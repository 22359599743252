import React, { useRef } from 'react';
import Navbar from '../components/home/Navbar.jsx'; // Use relative path
import Hero from '../components/home/Hero.jsx'; // Use relative path
import Kdrama from '../components/kdrama/Kdrama.jsx'; // Use relative path

const Home = () => {
  const kdramaRef = useRef(null);
  
  const handleScroll = () => {
    kdramaRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="bg-pink-300 h-screen overflow-scroll z-0 ">
      <Navbar />
      <Hero handleScroll={handleScroll} />
      <Kdrama refProp={kdramaRef} /> 
    </div>
  )
}

export default Home;
