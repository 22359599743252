import React, { useState } from 'react';
import getVideoId from 'get-video-id';

const RandomCard = ({ image, title, rating, url }) => {
  const [showVideoOverlay, setShowVideoOverlay] = useState(false);

  const handleCardClick = () => {
    setShowVideoOverlay(true);
  };

  const handleOverlayClick = () => {
    setShowVideoOverlay(false);
  };

  const getYouTubeEmbedUrl = (url) => {
    const videoId = getVideoId(url);
    if (videoId && videoId.id) {
      return `https://www.youtube.com/embed/${videoId.id}?modestbranding=1&hd=1&autoplay=1&loop=1`;
    }
    return '';
  };

  return (
    <div className="relative">
      <div className="bg-black h-[500px] w-full rounded-lg cursor-pointer" onClick={handleCardClick}>
        <div className="relative w-full h-full">
          <img src={image} alt={title} className="w-full h-full object-cover rounded-lg" />
          <div className="hover:opacity-30 absolute inset-0 bg-gradient-to-t from-black bg-opacity-80 rounded-lg"></div>
        </div>
        <h2 className="text-white text-lg sm-max:text-sm absolute top-[70%] px-4 left-0 right-0 text-center font-bold line-clamp-2">
          {title}
        </h2>
      </div>
      {showVideoOverlay && (
        <div
          onClick={handleOverlayClick}
          className="bg-black bg-opacity-80 fixed w-full h-full z-20 top-0 left-0 flex justify-center items-center"
        >
          <iframe
            className="w-[70%] h-[65%]"
            src={getYouTubeEmbedUrl(url)}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default RandomCard;
