import React, { useState } from "react";
import Navbar from "../components/home/Navbar.jsx";
import NewsCard from "../components/news/NewsCard.jsx";
import newsKdramaCard from "../components/news/newsKdramaCard.json";
import newsKpopCard from "../components/news/newsKpopCard.json";
import TopCard from "../components/news/TopCard.jsx";

const News = () => {
  const [kpopSortOrder, setKpopSortOrder] = useState("latest"); // Default sort order for K-POP NEWS is "latest"
  const [kdramaSortOrder, setKdramaSortOrder] = useState("latest"); // Default sort order for K-DRAMA NEWS is "latest"

  // Sort K-POP NEWS data based on the selected sort order
  const sortedKpopNews =
    kpopSortOrder === "oldest"
      ? [...newsKpopCard].sort((a, b) => new Date(a.date) - new Date(b.date))
      : [...newsKpopCard].sort((a, b) => new Date(b.date) - new Date(a.date));

  // Sort K-DRAMA NEWS data based on the selected sort order
  const sortedKdramaNews =
    kdramaSortOrder === "oldest"
      ? [...newsKdramaCard].sort((a, b) => new Date(a.date) - new Date(b.date))
      : [...newsKdramaCard].sort((a, b) => new Date(b.date) - new Date(a.date));

  return (
    <div className="bg-pink-300 h-screen">
      <Navbar />
      <div className=" bg-pink-300 mx-auto px-5 max-w-[1440px]">
        <h1 className="items-center text-center text-white text-3xl uppercase pt-[3rem] tracking-[13px] pb-5">
          {" "}
          News
        </h1>
        <div className="divide-y-2 divide-white">
          <div className="flex flex-row space-x-3 pb-1">
            <h2 className="items-center text-white text-2xl uppercase">
              {" "}
              K-POP NEWS{" "}
            </h2>
            <select
              value={kpopSortOrder}
              onChange={(e) => setKpopSortOrder(e.target.value)}
              className="bg-pink-100 rounded outline-none"
            >
              <option value="oldest" className="uppercase">
                OLD
              </option>
              <option value="latest" className="uppercase">
                NEW
              </option>
            </select>
          </div>
          <div className="pt-4 flex overflow-x-auto pb-5 pl-5 gap-6">
            {" "}
            {/* Add overflow-x-auto and pb-4 for horizontal scrolling */}
            {/* Mapping through the sorted K-POP NEWS data */}
            {sortedKpopNews.map((card, index) => (
              <NewsCard
                key={`kpop-${index}`}
                image={card.image}
                info={card.info}
              />
            ))}
          </div>
        </div>
        <div className="divide-y-2 divide-white">
          <div className="flex flex-row space-x-3 pb-1">
            <h3 className="items-center text-white text-2xl uppercase">
              {" "}
              K-DRAMA NEWS
            </h3>
            <select
              value={kdramaSortOrder}
              onChange={(e) => setKdramaSortOrder(e.target.value)}
              className="bg-pink-100 rounded outline-none"
            >
              <option value="oldest" className="uppercase">
                OLD
              </option>
              <option value="latest" className="uppercase">
                NEW
              </option>
            </select>
          </div>
          <div className="pt-4 flex overflow-x-auto pb-5 pl-5 gap-6">
            {" "}
            {/* Add overflow-x-auto for horizontal scrolling */}
            {/* Mapping through the sorted K-DRAMA NEWS data */}
            {sortedKdramaNews.map((card, index) => {
              if (card.style === "bot") {
                return (
                  <NewsCard
                    key={`card-${index}`}
                    image={card.image}
                    info={card.info}
                  />
                );
              } else if (card.style === "top") {
                return (
                  <TopCard
                    key={`card-${index}`}
                    image={card.image}
                    info={card.info}
                  />
                );
              } else {
                // Default fallback if the style is not recognized
                return null;
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
