import React from 'react';
import KdramaHero from './KdramaHero';
import KdramaList from './KdramaList';

const Kdrama = ({ refProp }) => {
  return (
    <div className="h-screen flex flex-col items-center" ref={refProp}>
        <KdramaHero/>
        <KdramaList/>
    </div>
  );
};

export default Kdrama;