import React, { useEffect, useState, useCallback} from "react";
import KdramaCard from "./KdramaCard";
import cardsData from "./cardsData.json";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const sortCards = (cardsData, sortOption) => {
  let sortedCards = [...cardsData];

  if (sortOption === "title a-z") {
    sortedCards.sort((a, b) => a.title.localeCompare(b.title));
  } else if (sortOption === "title z-a") {
    sortedCards.sort((a, b) => b.title.localeCompare(a.title));
  } else if (sortOption === "rating") {
    sortedCards.sort((a, b) => b.rating - a.rating);
  } else if (sortOption === "rating high to low") {
    sortedCards.sort((a, b) => b.rating - a.rating);
  } else if (sortOption === "rating low to high") {
    sortedCards.sort((a, b) => a.rating - b.rating);
  } else if (sortOption === "release") {
    sortedCards.sort((a, b) => new Date(b.release) - new Date(a.release));
  } else {
    // If the sortOption is empty or invalid, sort by title (default)
    sortedCards.sort((a, b) => a.title.localeCompare(b.title));
  }
  return sortedCards;
};

const KdramaList = () => {
  const calculateCardWidth = (gridWidth) => {
    const minCardWidth = 200; // Minimum width of each card
    const maxNumCols = Math.floor(gridWidth / minCardWidth); // Maximum number of columns
    const cardWidth = gridWidth / maxNumCols; // Width of each card
    return cardWidth;
  };

  const [numCols, setNumCols] = useState(() => {
    const gridWidth = window.innerWidth; // Width of the grid container
    const cardWidth = calculateCardWidth(gridWidth);
    return Math.floor(gridWidth / cardWidth); // Calculate the initial number of columns
  });

  const handleResize = useCallback(() => {
    const gridWidth = window.innerWidth; // Width of the grid container
    const cardWidth = calculateCardWidth(gridWidth);
    const newNumCols = Math.floor(gridWidth / cardWidth); // Calculate the new number of columns
    setNumCols(newNumCols > 7 ? 7 : newNumCols);
  }, []);

  useEffect(() => {
    // Update the number of columns when the window is resized
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const cappedNumCols = Math.min(numCols, 7);

  // Make a copy of cardsData and use it as the initial state for cards
  const [cards, setCards] = useState([...cardsData]);
  const [sortOption, setSortOption] = useState("");
  const [originalCardsData] = useState([...cardsData]);

  useEffect(() => {
    const sortedCards = sortCards(originalCardsData, sortOption);
    setCards(sortedCards);
  }, [sortOption, originalCardsData]);

  const cardsPerPage = Math.min(cappedNumCols * 2, 14); // Number of cards to display per page
  const totalPages = Math.ceil(cards.length / cardsPerPage); // Total number of pages
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [isTransitioning, setIsTransitioning] = useState(false); // Transition state

  const handlePrevPage = () => {
    if (currentPage > 1 && !isTransitioning) {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentPage(currentPage - 1);
        setIsTransitioning(false);
      }, 150); // Delay the state update to allow the transition to complete
    }
  };

  const [genreOption, setGenreOption] = useState("");

  const handleNextPage = () => {
    if (currentPage < totalPages && !isTransitioning) {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentPage(currentPage + 1);
        setIsTransitioning(false);
      }, 150); // Delay the state update to allow the transition to complete
    }
  };

  const startIndex = (currentPage - 1) * cardsPerPage;
  const endIndex = startIndex + cardsPerPage;
  const currentCards = cards.slice(startIndex, endIndex);

  const handleSortChange = (e) => {
    setSortOption(e.target.value); // Update the selected sort option
  };

  const handleGenreChange = (e) => {
    const selectedGenre = e.target.value; // Get the selected genre

    if (selectedGenre === "") {
      setCards(cardsData); // If "All Genres" is selected, set the cards to the original data
    } else {
      const filteredCards = cardsData.filter((card) =>
        card.genre.includes(selectedGenre)
      ); // Filter cards based on the selected genre
      setCards(filteredCards); // Update the cards with the filtered results
    }
    setGenreOption(selectedGenre); // Update the selected genre in the state
  };

  const [searchQuery, setSearchQuery] = useState(""); // State for storing the search query

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value); // Update the search query
  };

  const genresList = [
  "All Genres",
  "Action",
  "Business",
  "Comedy",
  "Coming-of-age story",
  "Crime",
  "Drama",
  "Fantasy",
  "Fiction",
  "Historical",
  "Horror",
  "Law",
  "Melodrama",
  "Military",
  "Mystery",
  "Political",
  "Psychological",
  "Revenge",
  "Romance",
  "Sci-fi",
  "Slice of Life",
  "Survival",
  "Suspense",
  "Supernatural",
  "Thriller",
  "Youth",
];

useEffect(() => {
  if (searchQuery === "") {
    setCards(cardsData);
  } else {
    const keywordsAndGenres = searchQuery.toLowerCase().split(" ");
    const keywords = [];
    const genres = [];

    // Separate keywords and genres
    keywordsAndGenres.forEach((item) => {
      if (genresList.includes(item)) {
        genres.push(item);
      } else {
        keywords.push(item);
      }
    });

    setCards((prevCards) => {
      return prevCards.filter((card) => {
        // Check if all keywords are present in the title
        const areKeywordsMatched = keywords.every((keyword) =>
          card.title.toLowerCase().includes(keyword)
        );

        // Check if all genres are present in the card's genres
        const areGenresMatched =
          genres.length === 0 ||
          (Array.isArray(card.genre) &&
            genres.every((genre) => card.genre.includes(genre.toLowerCase())));

        // Return true if all keywords and genres match the search
        return areKeywordsMatched && areGenresMatched;
      });
    });
  }
}, [searchQuery]);

  
  

  const handleInputClick = () => {
    setSearchQuery("");
  };

  return (
    <div className="mt-10 max-w-[1440px] mx-auto px-5 w-full">
      <div className="sm-above:flex sm-above:justify-start">
        <div className="space-x-2 pb-4 flex flex-row">
          <input
            style={{ outline: "none", height: "2.5rem" }}
            className="w-full sm-above:w-[40%] px-4 py-2 bg-pink-100 rounded hover:bg-pink-200 text-xs sm:text-sm md:text-md lg:text-lg"
            type="text"
            placeholder="Search for K-drama"
            onClick={handleInputClick}
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <select
            style={{ outline: "none", height: "2.5rem" }}
            className="w-full sm-above:w-[30%]  px-4 py-2 bg-pink-100 rounded hover:bg-pink-200 text-sm md:text-lg lg:text-xl"
            value={sortOption}
            onChange={handleSortChange}
          >
            <option value="">Sort By</option>
            <option value="title a-z">Title (A-Z)</option>
            <option value="title z-a">Title (Z-A)</option>
            <option value="rating high to low">Rating H to L</option>
            <option value="rating low to high">Rating L to H</option>
            <option value="release">Release Date</option>
          </select>
          <select
            style={{ outline: "none", height: "2.5rem" }}
            className="w-full sm-above:w-[25%] px-4 py-2 bg-pink-100 rounded hover:bg-pink-200 text-sm md:text-md lg:text-lg"
            value={genreOption}
            onChange={handleGenreChange}
          >
            <option value="">All Genres</option>
            <option value="Action">Action</option>
            <option value="Business">Business</option>
            <option value="Comedy">Comedy</option>
            <option value="Coming-of-age">Coming-of-age</option>
            <option value="Crime">Crime</option>
            <option value="Drama">Drama</option>
            <option value="Fantasy">Fantasy</option>
            <option value="Fiction">Fiction</option>
            <option value="Historical">Historical</option>
            <option value="Horror">Horror</option>
            <option value="Law">Law</option>
            <option value="Melodrama">Melodrama</option>
            <option value="Military">Military</option>
            <option value="Mystery">Mystery</option>
            <option value="Political">Political</option>
            <option value="Psychological">Psychological</option>
            <option value="Revenge">Revenge</option>
            <option value="Romance">Romance</option>
            <option value="Sci-fi">Sci-fi</option>
            <option value="Slice of Life">Slice of Life</option>
            <option value="Survival">Survival</option>
            <option value="Suspense">Suspense</option>
            <option value="Supernatural">Supernatural</option>
            <option value="Thriller">Thriller</option>
            <option value="Youth">Youth</option>
          </select>
        </div>
        <div className="space-x-2 pb-4 flex flex-row">
  <button
    className="px-4 py-2 h-10 w-full sm-above:w-[45%] bg-pink-100 rounded disabled:bg-pink-400 hover:bg-pink-200 text-sm md:text-lg lg:text-xl items-center justify-center"
    onClick={handlePrevPage}
    disabled={currentPage === 1 || isTransitioning}
  >
    <FontAwesomeIcon icon={faChevronLeft} />
  </button>
  <button
    className="px-4 py-2 h-10 sm-above:w-[45%] w-full  bg-pink-100 rounded disabled:bg-pink-400 hover:bg-pink-200 text-sm md:text-lg lg:text-xl items-center justify-center"
    onClick={handleNextPage}
    disabled={currentPage === totalPages || isTransitioning}
  >
    <FontAwesomeIcon icon={faChevronRight} />
  </button>
</div>
      </div>
      <div className="h-[580px]">
        {cards.length > 0 ? (
          <div
            className={`grid gap-3 grid-cols-${cappedNumCols} transition-opacity duration-300 ${
              isTransitioning ? "opacity-0" : "opacity-100"
            }`}
          >
            {currentCards.map((card) => (
              <KdramaCard
                url={card.url}
                key={card.id}
                image={card.image}
                title={card.title}
                rating={card.rating}
              />
            ))}
          </div>
        ) : (
          <div>There are no matches.</div>
        )}
      </div>
    </div>
  );
};
export default KdramaList;
