import "./App.css";
import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom"; // Use HashRouter here
import Home from "./pages/Home.jsx";
import News from "./pages/News.jsx";
import Random from "./pages/Random.jsx";
import Releases from "./pages/Releases";
import axios from "axios";
import { Toaster } from "react-hot-toast";
import { UserContextProvider } from "./context/UserContext.jsx";

axios.defaults.baseURL = "https://api.kdrama.lol";
//axios.defaults.baseURL = "http://localhost:8000";
axios.defaults.withCredentials = true;

function App() {
  return (
    <UserContextProvider>
      <Toaster position="top-right" toastOptions={{ duration: 2000 }} />
      <Router>
        <Routes>
          {/* Use `element` prop for the root route */}
          <Route path="/" element={<Home />} />
          <Route path="/random" element={<Random />} />
          <Route path="/news" element={<News />} />
          <Route path="/releases" element={<Releases />} />
        </Routes>
      </Router>
    </UserContextProvider>
  );
}

export default App;
