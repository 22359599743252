import React, { useState, useEffect, useCallback } from "react"; // Import the useState hook
import Navbar from "../components/home/Navbar.jsx";
import ReleasesCard from "../components/releases/ReleasesCard.jsx";
import releasesInfoCard from "../components/releases/releasesInfoCard.json";

const Releases = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const nextMonth = (currentMonth + 1) % 12 || 12;
  const currentTimestamp = currentDate.getTime(); // Get the current timestamp in milliseconds

  const [selectedMonth, setSelectedMonth] = useState(null);

  // Set the default value to "ONGOING" on component mount
  useEffect(() => {
    setSelectedMonth("ONGOING");
  }, []);

  // Filter the cards based on the selected month and ongoing releases
  const filteredReleases = releasesInfoCard.filter((release) => {
    // Get the month in two-digit format from the release date
    const releaseMonth = release.date.slice(5, 7);

    // Check for the "Ongoing" option or specific month
    const isOngoing =
      selectedMonth === "ONGOING" &&
      currentTimestamp >= new Date(release.date).getTime() &&
      currentTimestamp <= new Date(release["end-date"]).getTime();
    const isSpecificMonth = selectedMonth && releaseMonth === selectedMonth;

    return isOngoing || isSpecificMonth;
  });

  const calculateCardWidth = (gridWidth) => {
    const minCardWidth = 400; // Minimum width of each card
    const maxNumCols = Math.floor(gridWidth / minCardWidth); // Maximum number of columns
    const cardWidth = gridWidth / maxNumCols; // Width of each card
    return cardWidth;
  };

  const [numCols, setNumCols] = useState(() => {
    const gridWidth = window.innerWidth; // Width of the grid container
    const cardWidth = calculateCardWidth(gridWidth);
    return Math.floor(gridWidth / cardWidth); // Calculate the initial number of columns
  });

  const handleResize = useCallback(() => {
    const gridWidth = window.innerWidth; // Width of the grid container
    const cardWidth = calculateCardWidth(gridWidth);
    const newNumCols = Math.floor(gridWidth / cardWidth); // Calculate the new number of columns
    setNumCols(newNumCols > 3 ? 3 : newNumCols);
  }, []);

  useEffect(() => {
    // Update the number of columns when the window is resized
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const cappedNumCols = Math.min(numCols, 3);

  return (
    <div className="h-screen bg-pink-300">
      <Navbar />
      <div className=" bg-pink-300 mx-auto px-5 max-w-[1440px]">
        <h1 className="items-center text-center text-white text-3xl uppercase pt-[3rem] tracking-[13px] pb-8">
          Releases
        </h1>
        <div className="divide-y-2 divide-white">
          <div className="flex flex-row space-x-3 pb-3">
            <button
              onClick={() => setSelectedMonth("ONGOING")} // Set the value to "ONGOING" to indicate the ongoing option
              className={`bg-pink-100 p-2 rounded w-full flex-grow hover:bg-pink-200 font-bold ${
                selectedMonth === "ONGOING" ? "bg-pink-400" : ""
              }`}
            >
              Ongoing
            </button>
            <button
              onClick={() =>
                setSelectedMonth(currentMonth.toString().padStart(2, "0"))
              }
              className={`bg-pink-100 p-2 rounded w-full flex-grow hover:bg-pink-200 font-bold  ${
                selectedMonth === currentMonth.toString().padStart(2, "0")
                  ? "bg-pink-400 "
                  : ""
              }`}
            >
              {currentDate.toLocaleString("default", { month: "long" })}
            </button>
            <button
              onClick={() =>
                setSelectedMonth(nextMonth.toString().padStart(2, "0"))
              }
              className={`bg-pink-100 p-2 rounded w-full flex-grow hover:bg-pink-200 font-bold  ${
                selectedMonth === nextMonth.toString().padStart(2, "0")
                  ? "bg-pink-400"
                  : ""
              }`}
            >
              {new Date(currentDate.setMonth(nextMonth - 1)).toLocaleString(
                "default",
                { month: "long" }
              )}
            </button>
            {/* Add more buttons if needed */}
          </div>
          {/* Display the filtered cards */}
          <div className={`pt-4 grid grid-cols-${cappedNumCols} gap-4 pb-4`}>
            {filteredReleases.map((release) => (
              <ReleasesCard
                key={release.id}
                image={release.image}
                title={release.title}
                info={release.info}
                genre={release.Genre}
                watch={release.Watch}
                date={release.date}
                url={release.url}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Releases;
