import React, { useState, useEffect, useCallback } from "react";
import Navbar from "../components/home/Navbar.jsx";
import cardsData from "../components/kdrama/cardsData.json";
import RandomCard from "../components/random/RandomCard";

const Random = () => {
  const [randomCards, setRandomCards] = useState([]);

  const getRandomCards = () => {
    // Shuffle the cardsData array
    const shuffledCards = cardsData.sort(() => Math.random() - 0.5);
    // Get the first 3 cards from the shuffled array
    const selectedCards = shuffledCards.slice(0, 3);
    return selectedCards;
  };

  const handleRandomButtonClick = () => {
    const randomCards = getRandomCards();
    setRandomCards(randomCards);
  };

  useEffect(() => {
    // Get random cards when the component mounts
    const randomCards = getRandomCards();
    setRandomCards(randomCards);
  }, []); // Empty dependency array ensures this runs only once on mount


  return (
    <div className="bg-pink-300 h-screen">
      <Navbar />
      <div className=" bg-pink-300 mx-auto px-5 max-w-[1440px]">
        <h1 className="items-center text-center text-white text-3xl uppercase pt-[3rem] tracking-[13px] pb-5">
          Random
        </h1>
        <div className="flex justify-center mb-5">
          <button
            onClick={handleRandomButtonClick}
            className="bg-pink-100 p-2 rounded hover:bg-pink-200 font-bold w-full flex-grow"
          >
            Random
          </button>
        </div>
        <div className="gap-3 grid grid-cols-3 sm-max:grid-cols-1 align-content-start pb-10">
          {randomCards.map((card) => (
            <RandomCard
              url={card.url}
              key={card.id}
              image={card.image}
              title={card.title}
              rating={card.rating}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Random;
