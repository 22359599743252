import React, { useState, useEffect, useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import axios from 'axios';
import {toast} from 'react-hot-toast';

const FormRegister = ({ setForm}) => {

  const [showRegisterForm, setShowRegisterForm] = useState(true);
  const handleCloseForm = () => {
    setForm(false);
  };
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    username: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {username, email, password, confirmPassword} = formData
    if (password !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }

    // Email validation using regex
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!email.match(emailRegex)) {
    toast.error("Invalid email address.");
    return;
  }

  try {
      const { data } = await axios.post('/register', {
        username,
        email,
        password,
      });
      if(data.error) {
        toast.error(data.error)
      } else {
        setFormData({}) 
        toast.success('Register Successful!')
        setShowRegisterForm(false);
      }
    } catch (error) {
        console.log(error)
    }
  };

  const wrapperRef = useRef(null);

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    // Close the form if the click is outside the wrapper element
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setForm(false);
    }
  };

  return (
    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[30]">
      {showRegisterForm && (
      <form
        ref={wrapperRef}
        className="bg-white [h-300px] w-[400px] rounded-lg p-5 z-[30] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col space-y-4">
          <div className="flex justify-between items-center">
            <h1 className="font-bold text-2xl">Create an Account</h1>
            <AiOutlineClose
              onClick={handleCloseForm}
              className="sm:w-[15px] sm:h-[15px] lg:w-[20px] lg:h-[20px] cursor-pointer"
            />
          </div>

          <input
            placeholder="Username"
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            className="p-2 border border-gray-500 rounded-md focus:outline-none" 
          />

          <input
            placeholder="Email"
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="p-2 border border-gray-500 rounded-md focus:outline-none"
          />

          <input
            placeholder="Password"
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            className="p-2 border border-gray-500 rounded-md focus:outline-none"
          />

          <input
            placeholder="Confirm Password"
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            className="p-2 border border-gray-500 rounded-md focus:outline-none"
          />

          <button
            type="submit"
            className="bg-pink-300 text-white py-2 px-4 rounded-md hover:bg-pink-500"
          >
            Register
          </button>
        </div>
      </form>
      )}
    </div>
  );
};

export default FormRegister;


