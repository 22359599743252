import React from "react";

const NewsCard = ({ image, info }) => {
  return (
    <div className="hover:scale-105 duration-500 transition relative bg-white/60 h-[300px] w-[300px] rounded-lg cursor-pointer">
      <div className="relative w-[300px] h-[220px]">
        <img
          src={image}
          alt=""
          className="w-full h-full object-cover rounded-t-lg"
        />
      </div>
      <h2 className="text-black text-m absolute top-[75%] px-4 left-0 right-0 text-center font-bold">
        {info}
      </h2>
    </div>
  );
};

export default NewsCard;
